import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FastForwardIcon from '@material-ui/icons/FastForward';

const useStyles = makeStyles(theme => ({
  item: {
  },
  index: {
    color: theme.palette.primary.main,
    minWidth: '4em'
  },
  btn: {
    flexGrow: 1,
  },
  ibtn: {
    paddingRight: theme.spacing(1)
  },
  button: {
    color: '#ccc',
    justifyContent: 'left',
    textTransform: 'none',
    width: '100%',
    paddingLeft: theme.spacing(2)
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <div className={classes.item}>
      <Grid container>
        <Grid item className={classes.btn}><Button size="small" className={classes.button} onClick={e => props.setVideoChapter(props.config)} >{props.config.title}</Button></Grid>
        <Grid item className={classes.ibtn}><IconButton color="primary" size="small" onClick={e => props.setVideoChapter(props.config)}><FastForwardIcon /></IconButton></Grid>
      </Grid>
    </div>
  );
}