import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Vod from './Vod';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <>
      { props.config.length > 0 &&
        <Grid container direction="row" spacing={2} className={classes.root}>
          <Grid item xs={12} sm={6} md={4} lg={3} >
            <div className={classes.item}>
              <Vod config={props.defaultVideo} setVideoSrc={props.setVideoSrc} />
            </div>
          </Grid>
          {props.config.map((video, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Vod config={video} setVideoSrc={props.setVideoSrc} key={index} />
            </Grid>
          ))}
        </Grid>
      }
    </>
  );
}