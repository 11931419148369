import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Chapter from './Chapter';

const useStyles = makeStyles(theme => ({
  card: {
  },
  chapters: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    height: '100%'
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #666',
    color: "#ccc",
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '1px'
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.title}><h4>Sessions</h4></div>
      { props.config && props.config.length > 0 &&
        <div className={classes.chapters}>
        { props.config.map( (chapter, index) => (
          <Chapter index={index+1} config={chapter} setVideoChapter={props.setVideoChapter} key={index} />
        ))}
        </div>
      }
    </div>
  );
}