import "./helpers/Polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Amplify from "aws-amplify";

import "./styles/index.scss";

import Config from "./Config";
import App from "./App";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: Config.COGNITO_REGION,
    userPoolId: Config.COGNITO_USER_POOL_ID,
    identityPoolId: Config.COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: Config.COGNITO_APP_CLIENT_ID,
  },
  Storage: {
    region: Config.S3_REGION,
    bucket: Config.S3_BUCKET,
    identityPoolId: Config.COGNITO_IDENTITY_POOL_ID,
  },
  API: {
    endpoints: [
      {
        name: Config.BACKEND_API_NAME,
        region: Config.BACKEND_REGION,
        endpoint: Config.BACKEND_URL,
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

serviceWorker.unregister();
