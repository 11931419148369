import { Fetch } from "./FetchApi";
import Config from '../Config';

export const FetchConfig = async (url) => {
  const payload = {
    method: 'GET',
  }
  return await Fetch(url, payload);
};

export const GetConfigUrl = (id) => {
  return `${Config.CONFIG_URL_PREFIX}${id}${Config.CONFIG_URL_POSTFIX}?v=${new Date().getTime()}`;
}

export const GetUrl = (url) => {
  return url + '?v=' + new Date().getTime();
};

export const GetConfig = async (url) => {
  const config = await FetchConfig(url);
  if (config && config.years) {
    for (let i = 0; i < config.years.length; i++) {
      const year = config.years[i];
      for (let j = 0; j < year.tabs.length; j++) {
        const tab = year.tabs[j];
        if (tab.contentUrl) {
          const contentUrl = GetUrl(tab.contentUrl);
          const content = await FetchConfig(contentUrl);
          if (content) {
            config.years[i].tabs[j].videos = content;
          }
        }
      }
    }
  }
  return config;
};

export const GetDefaultVideo = (config) => {
  for (let i = 0; i < config.videos.length; i++) {
    if (config.defaultVideo === config.videos[i].video.id) {
      return config.videos[i];
    }
  }
};
