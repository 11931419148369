import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

import Banner from "components/layout/Header";

import RegisterBackground from "assets/images/register-background.jpg";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const NotFound = (props) => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <>
      <Banner />
      <div
        className="register-section"
        style={{ backgroundImage: `url(${RegisterBackground})` }}
      >
        <Container>
          <h3 style={{ paddingBottom: "16px" }}>Sorry, Page not Found.</h3>
          <div className={classes.button}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => history.push("/")}
            >
              return to home page
            </Button>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NotFound;
