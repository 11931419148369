import React, { useState, useEffect } from 'react';
import Moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import Vods from '../vods/Vods';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`event-tabpanel-${index}`}
      aria-labelledby={`event-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  tab: {
    color: '#ccc',
    fontSize: '0.85em',
    fontWeight: 500
  }
}));

export default (props) => {
  const classes = useStyles();
  const [value, setValue] = useState();

  useEffect(() => {
    // set default tab on load
    if (value === undefined && props.config && props.config.length > 0) {
      // setValue(0);
      // for (let i = 0; i < props.config.length; i++) {
      //   const week = props.config[i];
      //   const startDate = Moment(week.startDate).valueOf();
      //   const endDate = Moment(week.endDate).valueOf();
      //   const now = new Date().getTime();
      //   if ((startDate <= now) && (now < endDate)) {
      //     setValue(i);
      //   }
      // }
      setValue(props.config.length - 1);
    }
  }, [value, props.config]);

  return (
    <>
      { value >= 0 && props.config && props.config.length > 0 &&
        <div>
          <AppBar position="static" color="default" elevation={0} className={classes.root}>
            <Tabs
              value={value}
              onChange={(_, val) => setValue(val) }
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              {props.config.map((tab, index) => (
                <Tab className={classes.tab} label={tab.name} key={index} />
              ))}
            </Tabs>
          </AppBar>
          {props.config.map((tab, index) => (
            <TabPanel value={value} index={index} key={index}>
              <Vods defaultVideo={props.defaultVideo} config={tab.videos} setVideoSrc={props.setVideoSrc} />
            </TabPanel>
          ))}
        </div>
      }
    </>
  );
}
