import axios from 'axios';

export const Fetch = async (url, { method, body }) => {
  switch (method) {
    case 'GET': return await get(url);
    case 'POST': return await post(url, body);
    default:
      throw new Error(`Error: Unknown REST method.`);
  }
}

const get = async (url) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    }
    console.log(`Network error: ${response.status} - ${response.statusText}`);
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log(`Network error: ${error.response.status} - ${error.response.statusText}`);
      console.log(error.response.headers);
      console.log(error.response.data);
      throw new Error(`Network error: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      console.log(error.request);
      throw new Error(`Network error: request could not be completed.`);
    } else {
      console.log('Error', error.message);
      throw new Error(`Error: ${error.message}`);
    }
  }
}

const post = async (url, body) => {
  try {
    const response = await axios.post(url, body);
    if (response.status === 200) {
      return response.data;
    }
    console.log(`Network error: ${response.status} - ${response.statusText}`);
  } catch (error) {
    console.log(error);
    if (error.response) {
      console.log(`Network error: ${error.response.status} - ${error.response.statusText}`);
      console.log(error.response.headers);
      console.log(error.response.data);
      throw new Error(`Network error: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      console.log(error.request);
      throw new Error(`Network error: request could not be completed.`);
    } else {
      console.log('Error', error.message);
      throw new Error(`Error: ${error.message}`);
    }
  }
}
