import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PlayIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    backgroundColor: '#f5f5f5'
  },
  container: {
    position: 'relative',
    height: '0',
    paddingBottom: '56.25%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    textAlign: 'center',
    color: 'rgba(255,255,255,0.75)'
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-24px',
    marginLeft: '-24px',
  },
  play: {
    fontSize: '3em',
  },
  title: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    padding: theme.spacing(2),
  }
}));

export default (props) => {
  const classes = useStyles();
  return (
    <>
      {props.config && props.config.video && props.config.chapters &&
        <Card onClick={e => props.setVideoSrc(props.config.video, props.config.chapters)} className={classes.card} >
          <div className={classes.container} style={{backgroundImage: `url("${props.config.video.videoPoster}")`}}>
            <div className={classes.icon}><PlayIcon className={classes.play} /></div>
            <div className={classes.title}><h5 style={{fontWeight: 400}}>{props.config.name}</h5></div>
          </div>
        </Card>
      }
    </>
  );
}