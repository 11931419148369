import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/routes/AppliedRoute";
import AuthenticatedRoute from "./components/routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/routes/UnauthenticatedRoute";

import Home from "./pages/Home";
import Event from "./pages/Event";
import Login from "./pages/Login";
import Reset from "./pages/ResetPassword";
import ActivateAccount from "./pages/ActivateAccount";
import RegisterAdvisorBroker from "./pages/RegisterAdvisorBroker";
import RegisterInvestor from "./pages/RegisterInvestor";
import RegisterOther from "./pages/RegisterOther";
import RegisterResearchConsultant from "./pages/RegisterResearchConsultant";
import NotFound from "./pages/NotFound";

export default function Routes({ appProps, config }) {
  return (
    <Switch>
      <Route path="/:id" exact component={Event} appProps={appProps} />
      <Route component={NotFound} />
    </Switch>
  );
}
