import "./Header.scss";

import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#FFF",
    paddingTop: 36,
    paddingBottom: 36,
    boxShadow:
      "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
    zIndex: 5,
    position: "relative",
  },
  gridLogo: {
    cursor: "pointer",
  },
  container1: {
    textAlign: "left",
    marginBottom: "0",
  },
  container2: {
    maxWidth: "400px",
    // margin: "0 auto",
    textAlign: "left",
  },
  logo1: {
    maxWidth: '200px',
  },
  logo2: {
    width: "100%",
    height: "auto",
    margin: "0 auto",
    display: "block",
  },
  gridButtons: {
    textAlign: "right",
    alignSelf: "center",
  },
  buttonDefault: {
    marginRight: theme.spacing(2),
  },
  exitButtonContainer: {
    justifyItems: "flex-end"
  },
  "@media screen and (max-width: 500px)": {
    root: {
      paddingTop: 20,
      paddingBottom: 20,
    },
    exitButtonContainer: {
      justifyContent: "center",
      marginTop: 20
    },
  },
  "@media screen and (max-width: 960px)": {
    container1: {
      textAlign: "center",
      marginBottom: "10px",
    },
  },
}));

const Header = (props) => {
   console.log("props -> ", props);
   const {config} = props;
  const history = useHistory();
  const Home = async () => {
    history.push("/");
  };

  const quickExit = () => {
    window.open("https://www.google.com/", "_blank");
    window.open("http://www.bom.gov.au/", "_self");
  }
  const classes = useStyles();

  return (
    <AppBar
      position="static"
      color="default"
      className={classes.root}
      elevation={2}
    >
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          className="header-container"
        >
          <Grid
            item
            xs={12}
            md={6}
            // onClick={() => Home()}
            className={classes.gridLogo}
          >
            {props.config && props.config.eventPage &&
              <div className={classes.container2}>
              <img
                   src={config.eventPage.playerSection.bannerImage}
                  alt={config.eventPage.playerSection.bannerImageAltText}
                  className={classes.logo2}
              />
            </div>}

          </Grid>
          <Grid
          justify={"flex-end"}
            item
            className = {classes.exitButtonContainer}
            container
            xs={12}
            md={6}
          >
            {props.config && props.config.eventPage &&
             <button
                className="button-secondary button-exit"
                onClick={() => quickExit()}
              >
                QUICK EXIT
              </button>
            }
          </Grid>
          {props.isAuthenticated && (
            <div className="header-buttons">
              <button
                className="button-brand button-secondary"
                onClick={() => props.Logout()}
              >
                Log out
              </button>
              <a
                className="button-outline button-secondary"
                href="https://2021.magellaninreview.com.au"
                target="_blank"
                rel="noreferrer"
              >
                More Info
              </a>
            </div>
          )}
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Header;
