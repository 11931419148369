import { CircularProgress, Container, Grid, Paper } from "@material-ui/core";
import React, { useState } from "react";

import { Auth } from "aws-amplify";
import Banner from "components/layout/Header";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
// import RegisterBackground from "assets/images/register-background.jpg";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(3),
  },
  msg: {
    marginTop: "12px",
    color: "#f44336",
  },
  progress: {
    marginTop: "8px",
  },
  icon: {
    margin: "12px 12px",
    width: "100%",
    height: "auto",
    fill: "#31a9e0",
  },
}));

const Reset = (props) => {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [formMsg, setFormMsg] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const validateRequestCodeForm = () => {
    if (email.length === 0) {
      setFormMsg("Please enter an email address.");
      return false;
    }
    if (email.indexOf("@") === -1 || email.length <= 3) {
      setFormMsg("Please enter a valid email address.");
      return false;
    }
    return true;
  };

  const validateConfirmationForm = () => {
    if (code.length === 0) {
      setFormMsg("Please enter the activation code.");
      return false;
    }
    if (password.length === 0) {
      setFormMsg("Please enter a password.");
      return false;
    }
    if (password !== password2) {
      setFormMsg("Your passwords do not match.");
      return false;
    }
    return true;
  };

  const handleRequestCodeSubmit = async (event) => {
    event.preventDefault();
    if (validateRequestCodeForm()) {
      setIsWaiting(true);
      setFormMsg("Authenticating ...");
      try {
        await Auth.forgotPassword(email);
        setCodeSent(true);
        setIsWaiting(false);
        setFormMsg("");
      } catch (e) {
        console.log(e.message);
        setFormMsg(e.message);
        setIsWaiting(false);
      }
    }
  };

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    if (validateConfirmationForm()) {
      setIsWaiting(true);
      setFormMsg("Reseting password ...");
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        setConfirmed(true);
        setIsWaiting(false);
        setFormMsg("Reseting password ... done.");
      } catch (e) {
        console.log(e.message);
        setFormMsg(e.message);
        setIsWaiting(false);
      }
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    history.push("/login");
  };

  const renderConfirmationForm = () => {
    return (
      <>
        <p>
          Please check your email inbox: <b>{email}</b>
          <br />
          (and spam folder) for the activation code.
        </p>
        <form noValidate autoComplete="off" onSubmit={handleConfirmationSubmit}>
          <TextField
            id="code"
            label="Activation Code"
            value={code}
            type="tel"
            onChange={(e) => setCode(e.target.value.trim())}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <TextField
            id="password2"
            label="Confirm Password"
            type="password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          <Grid container>
            <Grid item xs={4}>
              <button
                type="submit"
                className="MuiFormControl-marginNormal button-submit button-secondary"
                disabled={isWaiting}
              >
                Submit
              </button>
            </Grid>
            <Grid item xs={1}>
              {isWaiting && (
                <CircularProgress size={20} className={classes.progress} />
              )}
            </Grid>
            <Grid item xs={7}>
              <h5 className={classes.msg}>{formMsg}</h5>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const renderRequestCodeForm = () => {
    return (
      <>
        <form noValidate autoComplete="off" onSubmit={handleRequestCodeSubmit}>
          <TextField
            id="username"
            label="Email"
            value={props.username}
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <Grid container>
            <Grid item xs={4}>
              <button
                type="submit"
                className="MuiFormControl-marginNormal button-submit button-secondary"
                disabled={isWaiting}
              >
                Submit
              </button>
            </Grid>
            <Grid item xs={1}>
              {isWaiting && (
                <CircularProgress size={20} className={classes.progress} />
              )}
            </Grid>
            <Grid item xs={7}>
              <h5 className={classes.msg}>{formMsg}</h5>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <Grid container>
        <Grid item xs={2}>
          <DoneOutlineIcon className={classes.icon} />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <p>Your password has been reset.</p>
          <button
            className="MuiFormControl-marginNormal button-submit button-secondary"
            onClick={handleLogin}
          >
            login with your new credentials
          </button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Banner/>
      <div className="register-section">
        <Container>
          <Grid container justify="center">
            <Grid item xs={10} sm={8} md={6} lg={5} className={classes.grid}>
              <Paper className={classes.paper}>
                <h2>Reset Password</h2>
                <div>
                  {!codeSent
                    ? renderRequestCodeForm()
                    : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Reset;
