import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import Footer from "./Footer";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFF",
    minHeight: "600px",
  },
}));

const SiteFrame = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>{props.children}</div>
      <Footer />
    </>
  );
};

export default SiteFrame;
