const config = {
  CLIENT_ID: "ICOURTS",
  BITMOVIN_ANALYTICS_KEY: "ade734cd-842a-4308-b630-9d3c3b709ce0",
  MUX_ANALYTICS_KEY: "g390j6uqqi612ienbuk94q10h",
  S3_REGION: "ap-southeast-2",
  S3_BUCKET: "events-public-assets",
  BACKEND_REGION: "ap-southeast-2",
  ZENDESK_API_TOKEN: "42pzgqiuUL6qTOtljOIJWcELj2lJN3qA9ADBL9Ig",
  CONFIG_URL_PREFIX: "https://events-public-assets.s3.ap-southeast-2.amazonaws.com/events/icourts/config/",
  CONFIG_URL_POSTFIX: "/index.json"
};

export default config;
