import { FetchConfig, GetUrl } from "./helpers/ConfigApi";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import Config from "./Config";
import CssBaseline from "@material-ui/core/CssBaseline";
import Loading from "./containers/Loading";
import Routes from "./Routes";
import SiteFrame from "./components/layout/SiteFrame";
import { useHistory } from "react-router-dom";
import { useInterval } from "./hooks/useInterval";

const App = () => {
  // const [user, setUser] = useState();
  // const [config, setConfig] = useState();
  // const [isInitialising, setIsInitialising] = useState(config === undefined);
  // const [isAuthenticated, userHasAuthenticated] = useState(true);
  // const [message, setMessage] = useState({
  //   type: "info",
  //   description: "Loading ... ",
  // });
  //const [url, setUrl] = useState(GetUrl(Config.URL_CONFIG));
  const history = useHistory();

  // useEffect(() => {
  //   // refresh token
  //   Auth.currentSession()
  //     .then((data) => console.log(data))
  //     .catch((err) => console.log(err));

  //   // look for logged in user
  //   Auth.currentAuthenticatedUser()
  //     .then((user) => {
  //       setUser(user);
  //       userHasAuthenticated(true);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  // useEffect(() => {
  //   if (url) {
  //     FetchConfig(url)
  //       .then((newConfig) => {
  //         if (newConfig) {
  //           setConfig(newConfig);
  //           setIsInitialising(false);
  //         }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //         setMessage({ type: "error", description: e.message });
  //       });
  //   }
  // }, [url]);

  // useInterval(() => {
  //   setUrl(GetUrl(Config.URL_CONFIG));
  // }, 60000);

  // const Logout = () => {
  //   Auth.signOut()
  //     .then(() => {
  //       userHasAuthenticated(false);
  //       setUser();
  //       history.push("/login");
  //     })
  //     .catch((e) => console.log(e.message));
  // };

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#000000de",
        contrastText: "#fff",
      },
      secondary: {
        main: "#315370",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(
        ","
      ),
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        {/* <SiteFrame isAuthenticated={isAuthenticated} Logout={Logout}>
          {isInitialising && <Loading message={message} />}
          {!isInitialising && (
            <Routes />
          )}
        </SiteFrame> */}
        <Routes />
      </div>
    </MuiThemeProvider>
  );
};

export default App;
