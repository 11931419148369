import React, { useState, useEffect, useMemo, useRef } from 'react';
import Moment from 'moment';
import { GetDefaultVideo } from '../../helpers/ConfigApi';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import VideoPlayer from '../video/VideoPlayerJS';
import Chapters from '../video/Chapters';
import Years from '../tabs/Years';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  background: {
    backgroundColor: '#222',
  },
}));

export default (props) => {
  const {config: {eventPage: {playerSection} = {} ={}}, config} = props;
  const defaultVideo = useMemo ( () => GetDefaultVideo(props.config), [props.config] );
  const playerRef = useRef();
  const [playerSrc, setPlayerSrc] = useState(defaultVideo.video);
  const [childSrc, setChildSrc] = useState(playerSrc);
  const [childChapter, setChildChapter] = useState();
  const [chapters, setChapters] = useState(defaultVideo.chapters);

  useEffect(() => {
    if (childChapter && childChapter.startTime) {
      const timecode = Moment.duration(childChapter.startTime).asSeconds();
      playerRef.current.setCurrentTime(timecode);
    }
  }, [childChapter]);

  const updatePlayerChapterFromChild = (chapter) => {
    setChildChapter(chapter);
  }

  useEffect(() => {
    setPlayerSrc(childSrc);
  }, [childSrc]);

  const updatePlayerSrcFromChild = (video, chapters) => {
    setChildSrc(video);
    setChapters(chapters);
  }

  useEffect(() => {
    // reload the video if the src has been updated in the config file
    if (playerSrc.id === props.config.defaultVideo.id) {
      setChildSrc(props.config.defaultVideo);
    }
  }, [playerSrc, props.config]);

  const classes = useStyles();
  return (
      <div className={classes.root}>
        <Grid container direction="row">
          <Grid item md={9} xs={12} className={classes.background}>
            <VideoPlayer src={playerSrc} ref={playerRef} userId='' />
          </Grid>
          <Grid item md={3} xs={12} className={classes.background} >
            <Chapters config={chapters} setVideoChapter={updatePlayerChapterFromChild}  />
          </Grid>
        </Grid>
        <Years config={props.config} setVideoSrc={updatePlayerSrcFromChild} defaultVideo={defaultVideo} />
        <div className="event-player-section">
          <div className="event-player-inner">
            <div className="event-player-info-container">
              <div className="event-player-info-description">
                <h2><strong>{playerSection.title1}</strong></h2>
                <h3>{playerSection.description} <span><a href={'mailto:'+`${playerSection.email}`}>{playerSection.email}</a></span></h3>
                <h2><strong>{playerSection.title2}</strong></h2>
                <h3>{playerSection.description2}</h3>
                <ul className="event-player-info-list">
                  {
                    playerSection.list.map((_item,key) => {
                      return <li><p><strong><a target="_blank" href={_item[2]} style={{color: '#000000'}}>{_item[0]}</a></strong> <span>{_item[1]}</span></p></li>
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
