export const VideoSrcIsEqual = (src1, src2) => {
  return (
    src1 &&
    src2 &&
    src1.videoUrl === src2.videoUrl &&
    src1.videoId === src2.videoId &&
    src1.videoTitle === src2.videoTitle &&
    src1.videoPoster === src2.videoPoster
  );
}

