import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";

// import RegisterBackground from "assets/images/register-background.jpg";

import Banner from "components/layout/Header";

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(3),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  msg: {
    marginTop: "12px",
    color: "#f44336",
  },
  progress: {
    marginTop: "8px",
  },
  icon: {
    margin: "12px 12px",
    width: "100%",
    height: "auto",
    fill: "#31a9e0",
  },
}));

const Activate = (props) => {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [formMsg, setFormMsg] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);

  const [codeSent, setCodeSent] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const validateRequestCodeForm = () => {
    if (email.length === 0) {
      setFormMsg("Please enter an email address.");
      return false;
    }
    if (email.indexOf("@") === -1 || email.length <= 3) {
      setFormMsg("Please enter a valid email address.");
      return false;
    }
    return true;
  };

  const validateConfirmationForm = () => {
    if (code.length === 0) {
      setFormMsg("Please enter the activation code.");
      return false;
    }
    var regex = new RegExp("^[0-9]+$");
    if (!regex.test(code)) {
      setFormMsg(
        "Please enter a valid code. Your activation code should be a 6 digit number."
      );
      return false;
    }
    return true;
  };

  const handleRequestCodeSubmit = async (event) => {
    event.preventDefault();
    if (validateRequestCodeForm()) {
      setIsWaiting(true);
      setFormMsg("Authenticating ...");
      try {
        await Auth.resendSignUp(email);
        setCodeSent(true);
        setIsWaiting(false);
        setFormMsg("");
      } catch (e) {
        console.log(e.message);
        setFormMsg(e.message);
        setIsWaiting(false);
      }
    }
  };

  const handleConfirmationSubmit = async (event) => {
    event.preventDefault();
    if (validateConfirmationForm()) {
      setIsWaiting(true);
      setFormMsg("Activating account ...");
      try {
        await Auth.confirmSignUp(email, code);
        setConfirmed(true);
        setIsWaiting(false);
        setFormMsg("Activating account ... done.");
      } catch (e) {
        console.log(e.message);
        setFormMsg(e.message);
        setIsWaiting(false);
      }
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    history.push("/login");
  };

  const renderConfirmationForm = () => {
    return (
      <>
        <p>
          Please check your email inbox: <b>{email}</b>
          <br />
          (and spam folder) for the activation code.
        </p>
        <form noValidate autoComplete="off" onSubmit={handleConfirmationSubmit}>
          <TextField
            id="code"
            label="Activation Code"
            value={code}
            type="tel"
            onChange={(e) => setCode(e.target.value.trim())}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <Grid container>
            <Grid item xs={4}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                disabled={isWaiting}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={1}>
              {isWaiting && (
                <CircularProgress size={20} className={classes.progress} />
              )}
            </Grid>
            <Grid item xs={7}>
              <h5 className={classes.msg}>{formMsg}</h5>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const renderRequestCodeForm = () => {
    return (
      <>
        <p>Please enter the email address you used to register an account.</p>
        <form noValidate autoComplete="off" onSubmit={handleRequestCodeSubmit}>
          <TextField
            id="username"
            label="Email"
            value={props.username}
            onChange={(e) => setEmail(e.target.value.trim())}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            autoFocus
          />
          <Grid container className={classes.submit}>
            <Grid item xs={4}>
              <button
                type="submit"
                className="MuiFormControl-marginNormal button-submit button-secondary"
                disabled={isWaiting}
              >
                Submit
              </button>
            </Grid>
            <Grid item xs={1}>
              {isWaiting && (
                <CircularProgress size={20} className={classes.progress} />
              )}
            </Grid>
            <Grid item xs={7}>
              <h5 className={classes.msg}>{formMsg}</h5>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <Grid container>
        <Grid item xs={2}>
          <DoneOutlineIcon className={classes.icon} />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={9}>
          <p>Your account has been activated.</p>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            login with your new credentials
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Banner/>
      <div className="register-section">
        <Container>
          <Grid container justify="center" className={classes.grid}>
            <Grid item xs={10} sm={8} md={6} lg={5}>
              <Paper className={classes.paper}>
                <h2>Activate Account</h2>
                <div>
                  {!codeSent
                    ? renderRequestCodeForm()
                    : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}
                </div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default Activate;
