import "./styles/Event.scss";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import React, { useEffect, useMemo, useState } from "react";
import { GetConfigUrl, GetConfig } from "../helpers/ConfigApi";
import { useInterval } from "../hooks/useInterval";
import { useParams } from "react-router-dom";
import Banner from "components/layout/Header";
import Content from "components/content/Content";
import Loading from "../containers/Loading";
import Footer from "components/layout/Footer";

const Event = (props) => {
  const {id} = useParams();
  const [url, setUrl] = useState(GetConfigUrl(id));
  const [isInitialising, setIsInitialising] = useState(true);
  const [config, setConfig] = useState();
  const [message, setMessage] = useState({
    type: "info",
    description: "Loading ... ",
  });
  
  useEffect(() => {
    if (url) {
      GetConfig(url).then((newConfig) => {
        if (newConfig) {
          setConfig(newConfig);
          setIsInitialising(false);
        }
      }).catch(e => {
        console.log(e);
        setMessage({ type: 'error', description: e.message });
      });
    }
  }, [url]);

  useInterval(() => {
    setUrl(GetConfigUrl(id));
  }, 60000);

  console.log('config=======', config);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#EC7D30",
        contrastText: "#fff",
      },
      secondary: {
        main: "#4C4847",
        contrastText: "#fff",
      },
    },
    typography: {
      fontFamily: ["Montserrat", "sans-serif"].join(
        ","
      ),
    },
  });
  console.log("event config : ", config);
  return (
    <MuiThemeProvider theme={theme}>
      
      {isInitialising && (
        <Loading message={message} />
      )}
      {!isInitialising && (
        <>
       <Banner config={config}/>
          <Container disableGutters>
            <Content config={config} />
          </Container>
       <Footer config={config}/>
       </>
      )}
      
    </MuiThemeProvider>
  );
};

export default Event;
