import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { Auth } from "aws-amplify";
import Banner from "components/layout/Header";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(3),
  },
  msg: {
    marginTop: "12px",
    color: "#f44336",
    fontWeight: "500",
  },
  msglink: {
    fontWeight: "700",
    "&:hover": {
      color: "#00b2ad",
      textDecoration: "underline",
    },
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  progress: {
    marginTop: "8px",
  },
  forgot: {
    marginTop: "12px",
    fontWeight: "500",
    textDecoration: "none",
    textAlign: "center",
    color: "#777",
    "&:hover": {
      color: "#00b2ad",
      textDecoration: "underline",
    },
  },
  buttons: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  buttonGroup: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const formFieldInitState = {
  value: "",
  error: false,
  errorMessage: "",
};

const Login = (props) => {
  const [email, setEmail] = useState(formFieldInitState);
  const [password, setPassword] = useState({
    ...formFieldInitState,
    showPassword: false,
  });
  const [formMsg, setFormMsg] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [isCodeMsg, setIsCodeMsg] = useState(false);
  const [isRegisterMsg, setIsRegisterMsg] = useState(false);
  const [isPasswordMsg, setIsPasswordMsg] = useState(false);

  const history = useHistory();

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateForm = () => {
    if (email.value.length === 0) {
      setEmail({
        ...email,
        error: true,
        errorMessage: "Please enter an email address.",
      });
      return false;
    }
    if (email.value.indexOf("@") === -1 || email.value.length <= 3) {
      setFormMsg("Please enter a valid email address.");
      return false;
    }
    if (password.value.length === 0) {
      setPassword({
        ...password,
        error: true,
        errorMessage: "Please enter an password.",
      });
      return false;
    }
    return true;
  };
  const handleResetPassword = async (event) => {
    history.push("/reset");
  };
  const handleRegister = async (event) => {
    history.push("/#register");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsCodeMsg(false);
    setIsRegisterMsg(false);
    setIsPasswordMsg(false);

    if (validateForm()) {
      setIsWaiting(true);
      setFormMsg("Authenticating ...");
      try {
        const user = await Auth.signIn(email.value, password.value);
        setFormMsg("Authenticating ... Success.");
        setIsWaiting(false);
        setFormMsg("");
        props.setUser(user);
        props.userHasAuthenticated(true);
        history.push("/event");
      } catch (e) {
        setFormMsg("");
        setIsWaiting(false);
        if (e.code === "UserNotConfirmedException") {
          setIsCodeMsg(true);
        } else if (e.code === "PasswordResetRequiredException") {
          setIsPasswordMsg(true);
        } else if (e.code === "NotAuthorizedException") {
          setFormMsg(e.message);
        } else if (e.code === "UserNotFoundException") {
          setIsRegisterMsg(true);
        } else {
          console.log(e);
          setFormMsg(e.message);
        }
      }
    }
  };

  const classes = useStyles();
  return (
    <>
      <Banner />
      <div className="register-section login">
        <div className="register-form-section login">
          <h2>
            <strong>Log in</strong>
          </h2>
          <form
            className="register-form-container"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextField
              id="username"
              label="Email"
              onChange={(e) =>
                setEmail({ value: e.target.value.trim().toLowerCase() })
              }
              margin="normal"
              variant="outlined"
              fullWidth
              autoFocus
              error={email.error}
              helperText={email.errorMessage || "Enter Email"}
            />

            <FormControl
              className="MuiFormControl-fullWidth MuiFormControl-marginNormal"
              variant="outlined"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                label="Password"
                type={password.showPassword ? "text" : "password"}
                onChange={(e) => setPassword({ value: e.target.value })}
                margin="normal"
                variant="outlined"
                fullWidth
                error={password.error}
                helperText={password.errorMessage || "Enter password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {password.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Grid
              container
              className={classes.submit}
              spacing={3}
              alignItems="center"
            >
              <Grid item>
                <button
                  type="submit"
                  className="button-brand button-secondary"
                  style={{ marginLeft: -2 }}
                  disabled={isWaiting}
                >
                  LOG IN
                </button>
              </Grid>
              <Grid item>
                {isWaiting && (
                  <CircularProgress size={20} className={classes.progress} />
                )}
              </Grid>
              <Grid item>
                {formMsg.length > 0 && (
                  <h5 className={classes.msg}>{formMsg}</h5>
                )}
                {isCodeMsg && (
                  <h5 className={classes.msg}>
                    Your account is not activated. Please{" "}
                    <Link to="/activate" className={classes.msglink}>
                      resend activation code
                    </Link>
                    , and complete the activation.
                  </h5>
                )}
                {isRegisterMsg && (
                  <h5 className={classes.msg}>
                    Email not found. You might need to{" "}
                    <Link to="/#register" className={classes.msglink}>
                      register
                    </Link>{" "}
                    first?
                  </h5>
                )}
                {isPasswordMsg && (
                  <h5 className={classes.msg}>
                    Your password needs to be{" "}
                    <Link to="/reset" className={classes.msglink}>
                      reset
                    </Link>{" "}
                    before you can login.
                  </h5>
                )}
              </Grid>
            </Grid>
          </form>
          <div className="login-buttons">
            <button
              className="button-grey button-secondary"
              onClick={handleRegister}
            >
              Register
            </button>

            <button className="button-forgot" onClick={handleResetPassword}>
              Forgot password
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
