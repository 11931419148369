import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import SocialIcons from "./SocialIcons";

const Footer = (props) => {
  const {config} = props;
  return (
    <div className="footer">
      <Container>
        <Grid
          container
          direction="row"
          justify="space-between"
          className="footer-inner">
          <Grid item>
            {/*<h4 className="footer-heading">*/}
            {/*  <a*/}
            {/*    href="https://magellaninreview.events.corrivium.live"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*    className="footer-heading footer-link"*/}
            {/*  >*/}
            {/*    Magellan <strong>InReview 2021</strong>*/}
            {/*  </a>*/}
            {/*</h4>*/}
            <div>
              <a
                href= {config.eventPage.playerSection.footerUrlLink}
                target="_blank"
                rel="noopener noreferrer"
                className="footer-text footer-link"
              >
                {config.eventPage.playerSection.footerUrlText}
              </a>
            </div>
            {/* <SocialIcons /> */}
          </Grid>
          <Grid item>
            <h4 className="footer-text">
              &copy; Copyright 2022 |{" "}
              <a
                href="https://www.corrivium.com"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-text footer-link"
              >
                Corrivium Live
              </a>
            </h4>
            <div className="footer-text">All Rights Reserved</div>
            <a
              href="https://www.corrivium.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-text footer-link"
            >
              Privacy Policy
            </a>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
